import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

String.prototype.toCapitalCase = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

Vue.config.productionTip = false;

// Vue-Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './styles/style.scss'

Vue.use(BootstrapVue);
import('./guard/permission.guard')

// Element UI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI, { locale });

import filters from './filters'
Vue.use(filters);

// Vue Progress bar
import VueProgressBar from 'vue-progressbar';
Vue.use(VueProgressBar, {
  color: '#30b6cf',
  failedColor: '#874b4b',
  height: '2px',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})

// Datepicker
import Datepicker from 'vuejs-datepicker';
Vue.use(Datepicker);

// Vue generic HTTP Client
import VueResource from 'vue-resource'
Vue.use(VueResource);

// // Toastr Service
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/index.css';
Vue.use(VueToast, {position: 'top-right'});

// Debounce functionality
import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce, {
  defaultTime: '500ms'
});

// HTTP Client
import apix from './plugins/http-client';
Vue.use(apix);

Vue.http.options.root =  process.env.VUE_APP_BASE_URL;

// Vue Scroll
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

// Numeric filter
import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller);

import ECharts from 'vue-echarts' // refers to components/ECharts.vue in webpack

// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'



// register component to use
Vue.component('v-chart', ECharts)

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faUser,
  faUsers,
  faUserSecret,
  faFile,
  faSearch,
  faPlus,
  faMinus,
  faLink,
  faTimesCircle,
  faComment,
  faCircle,
  faCircleQuestion,
  faQuestion,
  faClock,
  faUserClock,
  faTimes,
  faCheck,
  faSearchDollar,
  faUpload,
  faDownload,
  faLock,
  faUnlock,
  faEdit,
  faPrint,
  faCode,
  faList,
  faListAlt,
  faFileAlt,
  faChevronDown,
  faChevronRight,
  faMailBulk,
  faPhoneAlt,
  faAngleDoubleRight
} from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import {
  faInstagram,
  faFacebook,
  faTelegramPlane,
  faTwitter, faInternetExplorer,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faBars,
    faInstagram,
    faFacebook,
    faUsers,
    faTimes,
    faCheck,
    faPlus,
    faMinus,
    faLink,
    faEdit,
    faComment,
    faCircle,
    faCircleQuestion,
    faQuestion,
    faUser,
    faUserSecret,
    faFile,
    faFileAlt,
    faClock,
    faUserClock,
    faFileExcel,
    faInternetExplorer,
    faPrint,
    faSearch,
    faMailBulk,
    faTelegramPlane,
    faPhoneAlt,
    faUpload,
    faDownload,
    faLock,
    faUnlock,
    faTimesCircle,
    faChevronDown,
    faChevronRight,
    faTwitter,
    faSearchDollar,
    faList,
    faListAlt,
    faCode,
    faAngleDoubleRight);
Vue.component('font-awesome-icon', FontAwesomeIcon)

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar);

new Vue({
  router,
  store: store(),
  render: h => h(App),
}).$mount('#app');

